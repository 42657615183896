@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

*{
  scroll-behavior: smooth;
}
/* body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    background: #F6F6F5;
    scroll-behavior: smooth;
} */

body{
  font-family: 'Rubik', sans-serif;
  background: #ffffff;
    scroll-behavior: smooth;
}
input,select{
  outline: none;
}
.batch_heart{
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.bannerFont {
  @apply mt-12 ml-6 text-2xl font-bold md:text-7xl md:font-bold md:mt-52 md:ml-32;
}

.bannerFont2 {
  @apply mt-12 ml-6 text-2xl font-bold md:text-6xl md:font-bold md:mt-52 md:ml-32;
}

.OtherBuyAbleFont {
  @apply text-xl font-semibold md:text-5xl md:font-bold;
}

.subtilte {
  @apply mt-0 ml-6 text-lg font-semibold text-white md:text-white md:font-semibold md:text-3xl md:mt-4 md:ml-32;
}

.titleText {
  @apply mt-2 ml-6 text-base font-normal text-white md:text-white md:font-normal md:text-2xl md:mt-4 md:ml-32;
}

.prevArrow-div {
  @apply ml-0 top-24 md:ml-10 md:top-72;
}

.prevArrow-div-icon {
  @apply w-6 h-6 p-1 font-extrabold text-white bg-yellow-600 cursor-pointer rounded-xl md:h-14 md:w-14 md:p-2 md:font-bold md:rounded-xl hover:bg-green-600 hover:text-white hover:rounded-xl;
}


.nextArrow-div {
  @apply mr-0 top-24 md:mr-10 md:top-72;
}

.nextArrow-div-icon {
  @apply w-6 h-6 p-1 font-extrabold text-white bg-yellow-600 cursor-pointer rounded-xl md:h-14 md:w-14 md:p-2 md:font-bold md:rounded-xl hover:bg-green-600 hover:text-white hover:rounded-xl;
}

/* coming from site.css */
.headerUpperh5 {
  @apply text-xs text-white flex font-semibold;
}

.logoName {
  @apply text-lg font-bold md:text-2xl text-white;
}

.product-action {
  @apply flex items-center justify-center gap-4 px-2 py-2 bg-transparent;
}

.product-action-link {
  @apply flex items-center justify-center w-auto gap-2;
}

.product-action-icon {
  @apply text-2xl text-white;
}

.product-action-name {
  @apply text-xs text-white;
}

.badge {
  @apply bg-white text-black p-[0.5px] items-center justify-center flex rounded-full mb-1;
}

.categoryDropDownul{
  @apply absolute z-50 visible py-1 mt-48 transition duration-300 ease-out rounded shadow opacity-100 bg-green1 dark:bg-gray-800 w-52;
}

.categoryDropDownli{
  @apply px-3 py-3 text-sm font-normal leading-3 tracking-normal text-white cursor-pointer dark:text-gray-400 dark:hover:text-white hover:bg-green3 hover:text-white;
}

/* Header and Navigation section ends here */


/* Our Store Section Goes here  */

.OurStoreColorPick {
  @apply flex flex-row flex-wrap gap-2;
}

.OurStoreColorPick li {
  @apply w-4 h-4 p-3 bg-blue-500 border-white rounded-full;
}


/* .OurStorDropDownul{
  @apply absolute visible transition duration-300 opacity-100 ease-out bg-green1 dark:bg-gray-800 shadow rounded mt-64 py-1 w-52 z-50 lg:ml-[69px];
} */

.OurStorDropDownul{
  @apply leading-loose transition duration-300 opacity-100 ease-out text-white bg-green1 dark:bg-gray-800 shadow rounded lg:mt-[0] py-2 px-4;
}

.OurStorDropDownli {
   @apply px-3 py-3 text-sm font-normal leading-loose tracking-wider text-white cursor-pointer dark:text-gray-400 dark:hover:text-white hover:bg-green3 hover:text-white;
}

/* Our Store Section ends here */
.InfoContainerWrapper {
  /* @apply flex items-center justify-between w-full h-auto px-20 py-6 text-gray-600 bg-gray-200; */
  /* @apply flex flex-row items-center justify-between w-full h-auto gap-6 md:gap-0 px-2 md:px-20 py-6 md:grid md:grid-cols-5 text-gray-600 bg-blue-500 overflow-x-scroll md:overflow-x-auto; */
  @apply flex md:justify-between text-gray-600 md:px-8;
}

.infoContainer {
  @apply flex-col md:flex-row flex w-auto gap-4 md:gap-0 items-start justify-start  md:items-center md:justify-center md:px-4 md:py-2 py-4 bg-gray-300;
}

.HomeInfoImg img {
  @apply w-8 md:w-10;
}

.HomeInfoTitle{
  @apply pt-1 flex flex-col md:grid md:grid-cols-1;
}

.HomeInfoTitleHeader {
  @apply md:text-sm text-xs font-bold text-green3 md:text-black text-center md:text-start;
}

.HomeInfoTitleText {
  @apply text-xs font-normal md:font-medium text-black text-center md:text-start;
}


.HomeDisplayContainerWrapper {
  @apply flex mt-0 md:mt-4 w-auto py-4 md:py-0 gap-3 justify-between md:grid md:grid-cols-5 overflow-x-scroll md:overflow-x-auto;
}

.HomeDisplayContainer {
  @apply  items-center justify-center flex-col p-3 md:flex md:flex-row  md:gap-0 md:items-center md:justify-evenly;
}

.HomeDisplayImg {
  @apply text-black hidden sm:block;
}

.HomeDisplayImgDisplay {
  @apply items-start w-20 h-14 flex;
}

HomeDisplayTitle{
  @apply bg-green1;
}

.HomeDisplayTitleHeader {
  @apply md:text-sm text-xs font-bold text-black text-center md:text-start;
}

.HomeDisplayTitleText {
  @apply text-xs font-medium text-black text-center md:text-start;
}


.HomeMarqueeContainerWrapper {
  @apply flex w-full h-auto p-5 mt-5 text-gray-600 bg-white gap-y-5;
}


.MarqueeSlider {
  @apply flex items-start w-12 h-auto bg-white justify-evenly;
}

.HomeMarqueeContainer {
  @apply flex items-center p-3 mx-auto bg-white;
}

.HomeMarqueeImg {
  @apply text-black;
}

.HomeMarqueeImgDisplay {
  @apply items-start h-24 bg-white w-44;
}

.HomeMarqueeImgDisplay2 {
  @apply items-start h-10 bg-white w-44;
}

.HomeMarqueeTitleHeader {
  @apply text-sm font-bold text-black;
}

.HomeMarqueeTitleText {
  @apply text-xs font-medium text-black;
}

/* Home marquee section ends here */


/* Products section starts here */
.product-card {
  @apply relative p-2 overflow-hidden border border-gray-300 sm:p-6;
}

.product-card:hover .product-card-action {
  @apply right-6 bg-white w-auto h-auto p-2 rounded-xl text-white 
}

.product-card-action {
  @apply absolute top-10 right-[-30px] transition ease-linear delay-150 hover:-translate-y-1 hover:scale-110 duration-1000;
}

.product-card:hover .product-card-whistlist {
  @apply right-8
}

.product-card-whistlist {
  @apply absolute top-[5%] right-[32px] transition ease-linear delay-150 hover:-translate-y-1 hover:scale-110 duration-1000;
}
/* Products section ends here */


/* HomePopularProducts goes here  */
.HomePopularProduct-card {
  @apply relative overflow-hidden border border-gray-300 px-2 pt-6 pb-2 max-h-64 md:max-h-96 bg-white rounded-lg mt-4;
}

.HomePopularProduct-card:hover .HomePopularProduct-card-action {
  @apply right-3 bg-white w-auto h-auto py-2 px-1 rounded-xl text-white mt-4 md:mt-4;
}

.HomePopularProduct-card-action {
  @apply absolute top-6 md:top-10 right-[-30px] transition ease-linear delay-150 hover:-translate-y-1 hover:scale-110 duration-1000;
}

.HomePopularProduct-card:hover .HomePopularProduct-card-whistlist {
  @apply right-4 transition ease-linear delay-150 hover:-translate-y-1 hover:scale-110 duration-1000
}

.HomePopularProduct-card-whistlist {
  @apply absolute mt-[-3%] md:top-[10%] right-[16px] overflow-hidden w-5 h-5 z-50;

}


/* .HomePopularProduct-Nav-Div{
  @apply flex w-full h-auto  p-0 md:p-3 bg-white md:flex-col items-start justify-between sm:justify-normal;
} */

.HomePopularProduct-Nav-Div-Items{
@apply flex flex-row items-center justify-center gap-1 md:gap-4 mb-2 leading-10 sm:leading-none ;
}

.HomePopularProduct-Nav-Div img{
  @apply w-4 h-4 md:w-10 md:h-10 p-0 md:p-1;
}

.HomePopularProduct-Nav-Div .links{
  @apply text-sm font-semibold text-gray-700 ;
}

.HomePopularProduct-Nav-Div .links:hover, .HomePopularProduct-Nav-Div .links:active {
  @apply text-sm font-semibold text-black ;
}
/* HomePopularProducts ends here */

/* HomeSpecialProducts span goes here */
.HomeSpecialProducts-card {
  @apply relative grid items-start justify-center w-full grid-cols-1 md:grid-cols-2 gap-0 p-2 mx-auto bg-gray-400;
}

.HomeSpecialProducts-card:hover .HomeSpecialProducts-card-action {
  @apply w-auto h-auto p-2 text-white bg-white right-4 rounded-xl ; 
}

/* .HomeSpecialProducts-card-section1 {
  @apply relative p-2 overflow-hidden bg-white rounded-t-lg sm:rounded-tr-none sm:rounded-tl-lg sm:rounded-bl-lg sm:max-h-96 sm:min-h-96;
} */


 .HomeSpecialProducts-card-action {
  @apply absolute top-16 right-[-30px] transition ease-linear delay-150 hover:-translate-y-1 hover:scale-110 duration-1000;
}

/*
.product-card:hover .product-card-whistlist {
  @apply right-8
}

.product-card-whistlist {
  @apply absolute top-[5%] right-[32px] transition ease-linear delay-150 hover:-translate-y-1 hover:scale-110 duration-1000;
} */



.HomeSpecialProductsSpan {
  @apply items-center justify-center p-1 ml-1 mr-1 text-xs text-white rounded-full bg-green1;
}

/* HomeSpecialProducts span ends here */



/* coppied from former naija wizard project */

.bannerFont {
  @apply mt-6 ml-6 text-lg font-bold md:text-7xl md:font-bold md:mt-44 md:ml-20 text-white;
}

.bannerFont2 {
  @apply mt-12 ml-6 text-2xl font-bold md:text-6xl md:font-bold md:mt-52 md:ml-20;
}

.OtherBuyAbleFont {
  @apply text-xl font-semibold md:text-5xl md:font-bold;
}

.subtilte {
  @apply mt-0 ml-6 text-base font-semibold text-white md:text-white md:font-semibold md:text-3xl md:mt-4 md:ml-20;
}

.titleText {
  @apply mt-2 ml-6 text-sm font-normal text-white md:text-white md:font-normal md:text-2xl md:mt-4 md:ml-20;
}

.prevArrow-div {
  @apply ml-0 top-24 md:ml-10 md:top-72;
}

.prevArrow-div-icon {
  @apply w-6 h-6 p-1 font-extrabold text-white bg-yellow-600 cursor-pointer rounded-xl md:h-14 md:w-14 md:p-2 md:font-bold md:rounded-xl hover:bg-green-600 hover:text-white hover:rounded-xl;
}


.nextArrow-div {
  @apply mr-0 top-24 md:mr-10 md:top-72;
}

.nextArrow-div-icon {
  @apply w-6 h-6 p-1 font-extrabold text-white bg-yellow-600 cursor-pointer rounded-xl md:h-14 md:w-14 md:p-2 md:font-bold md:rounded-xl hover:bg-green-600 hover:text-white hover:rounded-xl;
}



/* This is for event slider */
.prevArrow-div2 {
  @apply ml-2 mt-0 md:ml-3 md:mt-0;
}

.prevArrow-div-icon2 {
  @apply w-6 h-6 p-1 font-extrabold text-white bg-deepblue cursor-pointer rounded-xl md:h-10 md:w-10 md:p-2 md:font-bold md:rounded-3xl hover:bg-lightenblue hover:text-white;
}


.nextArrow-div2 {
  @apply mr-2 mt-0 md:mr-3 md:mt-0;
}

.nextArrow-div-icon2 {
  @apply w-6 h-6 p-1 font-extrabold text-white bg-deepblue cursor-pointer rounded-xl md:h-10 md:w-10 md:p-2 md:font-bold md:rounded-3xl hover:bg-lightenblue hover:text-white ;
}
/* This is for Event Slider */



.hide {
  @apply hidden p-0 m-0 md:block md:visible;
}

.hide2 {
  @apply hidden p-0 m-0 lg:block lg:visible;
}

.hide3 {
  @apply hidden p-0 m-0 sm:inline-flex sm:visible;
}

.hide4 {
  @apply visible p-0 m-0 lg:hidden;
}

.hide5 {
  @apply hidden p-0 m-0 sm:inline-flex sm:visible;
}


.store-botton {
  @apply w-full flex flex-grow items-center justify-center mt-[-10px] md:mt-[0px] py-0 md:py-2;
}


.download-app-button {
  @apply flex items-center justify-between px-1 py-1 mt-2 border-0 rounded-lg lg:border-2 xl:px-3 lg:px-3 md:mt-0 gap-x-3 md:gap-x-0;
}

/* .officialvendor-singleimg-container {
  @apply w-full overflow-hidden bg-gray-200 rounded-lg aspect-h-1 aspect-w-1 xl:aspect-h-8 xl:aspect-w-7 h-72 ;
} */



/* For User Register form */
.registerInput{
  @apply block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
}


/* The joined css goes here */

.bannerHeading {
  @apply px-4 mt-4 text-lg font-semibold leading-tight text-white md:mt-2 lg:mt-2 md:text-3xl md:font-extrabold md:leading-tight lg:leading-normal;
}

.OtherBuyAbleFont {
  @apply text-xl font-semibold md:text-5xl md:font-bold;
}

.subtilteHeading {
  @apply px-4 mt-2 text-sm font-semibold text-justify text-white md:text-white md:font-semibold md:text-xl md:mt-2 md:mr-20 lg:mt-2 xl:mt-4 lg:mb-6 xl:mb-0;
}

.prevArrow-div {
  @apply ml-0 top-20 md:ml-2 lg:ml-2 xl:ml-10 md:top-36;
}

.prevArrow-div-icon {
  @apply w-6 h-6 p-1 font-extrabold text-white rounded-full cursor-pointer bg-deepblue md:h-12 md:w-12 md:p-2 md:font-bold md:rounded-full hover:bg-lightenblue hover:text-white hover:rounded-full;
}


.nextArrow-div {
  @apply mr-0 top-20 md:mr-2 lg:mr-2 xl:mr-10 md:top-36;
}

.nextArrow-div-icon {
  @apply w-6 h-6 p-1 font-extrabold text-white rounded-full cursor-pointer bg-deepblue md:h-12 md:w-12 md:p-2 md:font-bold md:rounded-full hover:bg-lightenblue hover:text-white hover:rounded-full;
}



.h1{
  @apply text-xl font-extrabold md:text-3xl lg:text-4xl;
}


.h2{
  @apply text-xl font-extrabold md:text-3xl lg:text-3xl;
}

.h3 {
  @apply text-lg font-bold md:text-xl lg:text-2xl; 
}

.h4 {
  @apply text-lg font-medium text-black lg:font-semibold xl:font-bold md:text-lg lg:text-lg xl:text-xl; 
}

.h5 {
  @apply mb-0 font-bold text-green4 sm:mb-2 md:mb-2 lg:mb-3 xl:mb-3 lg:text-lg;
}

.h6 {
  @apply mb-0 font-bold text-green4 sm:mb-2 md:mb-2 lg:mb-3 xl:mb-3;
}

.hide {
  @apply hidden p-0 m-0 md:block md:visible;
}


/* For User Register form */
.registerInput{
  @apply block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
}

/* Code for wumbis goes here */

/* Wumbis footer section goes here */
.loginandregisterButtom{
  @apply flex items-center justify-center px-2 py-2 text-[7px] font-semibold text-white shadow-sm md:text-sm md:px-1 lg:px-2 xl:px-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2;
}


/* Footer section goes here */

.footerContainer{
  @apply bg-black;
}

.footerh3{
  @apply text-lg font-semibold leading-6 text-white;
}

.footerLink{
  @apply text-base font-medium leading-none text-gray-300 lg:leading-3 hover:text-white;
}



.tabs:not(:last-child){
  @apply border-r-2 border-blue-800
}

.active-tabs{
  @apply bg-white border-2 border-red-700
}

.active-tabs::before{
  @apply content-none block absolute left-16 -top-5 bg-green-500
}

button{
  @apply border-2
}


.coontent-tabs{
  @apply flex-grow
}

.content {
  @apply block p-3 bg-white w-80;
}

.content h2{
@apply p-3
}

.content hr{
  @apply w-72 h-6 bg-gray-600 mb-6
}

.content p{
  @apply w-72 h-7;
}

.active-content{
  @apply block;
}

.registerButton{
  @apply items-center w-full px-2 py-3 mt-2 text-sm font-medium text-center text-white bg-red-600 rounded-sm lg:px-4 md:py-2 lg:items-start md:text-base hover:bg-black hover:text-white lg:w-auto;
}

.registerButton2{
  @apply items-center w-full px-2 py-3 mt-2 text-sm font-medium text-center text-white rounded-sm bg-deepblue lg:px-4 md:py-2 lg:items-start md:text-base hover:bg-black hover:text-white lg:w-auto;
}

.connectAdvisorButton{
  @apply items-center w-full px-2 py-3 mt-3 text-sm font-semibold text-center text-white rounded-lg bg-deepblue md:px-4 md:py-2 md:items-start md:text-base hover:bg-black hover:text-white md:w-auto;
}

.connectAdvisorButton2{
  @apply items-center w-full px-2 py-3 mt-3 text-sm font-semibold text-center text-white rounded-lg md:px-4 md:py-2 md:items-start md:text-base hover:text-white md:w-auto;
}


.makepaymentButton{
  @apply items-center w-full px-2 py-3 mt-8 text-sm font-semibold text-center text-black bg-white rounded-lg md:px-4 md:py-2 md:items-start md:text-base hover:bg-black hover:text-white md:mt-8 md:w-auto;
}

.courseSideBarButton{
  @apply items-center w-full px-2 py-3 mt-2 text-sm font-semibold text-center text-white rounded-lg bg-deepblue md:px-4 md:py-2 md:items-start md:text-base hover:bg-black hover:text-white md:w-auto;
}


.generalConsultationDiv{
  @apply mt-4 md:py-6 md:-mt-4;
}


.smoothScrol{
  @apply transition duration-700 ease-in-out scroll-smooth focus:scroll-auto;
}


.ourteamImage {
  @apply w-full h-[320px] md:h-[320px] lg:h-[320px] xl:h-[450px] rounded-full border-8 border-b-white border-deepblue;
}

.ourteamNameDiv{
  @apply w-full pt-12 pb-4 mt-1 rounded-md rounded-b-full md:pb-4 md:pt-12 lg:pb-8 lg:pt-12 xl:pb-6 xl:pt-16 md:mt-0 lg:mt-1 xl:-mt-3 bg-deepblue;
}

.ourteamSecondParagraph{
  @apply text-xs leading-4 text-center text-white md:text-sm xl:text-base md:mt-1;
}


.testimonials-card {
  @apply relative overflow-hidden;
}

.shopbycategoryList{
  @apply items-center w-full px-4 py-6 text-white bg-lightenblue rounded-b-lg;
}

.happclientParagraph{
  @apply mt-4 text-sm md:text-xl lg:text-lg leading-none text-center text-white
}